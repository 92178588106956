import { useSelector } from 'react-redux'
import { uploadUrl } from '../api'
import { RootState } from '../store'
import { AuthState } from '../store/authSlice'
//
export type EnabledLanguage = 'ge' | 'en'
export const supportedLocales: EnabledLanguage[] = ['ge', 'en']
export const isSupportedLanguage = (lang: string | null) => lang && supportedLocales.some((l) => l === lang)

export type TranslationsField<TFields> = Partial<Record<EnabledLanguage, Partial<TFields> | undefined>>
//
//
export const georgianFormatter = new Intl.NumberFormat('ka-GE', {
  // style: 'currency',
  currency: 'GEL',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
})
//
//urls
export const getProductEditUrl = (productID: string | number) => `/admin/travel-product-edit/${productID}`
//
//
type CurrencyCode = 'eur' | 'usd' | 'gel'

export const currencySymbol = (currency?: CurrencyCode | string | undefined) => {
  const renderCurrency: Record<CurrencyCode, string> = {
    eur: '€',
    usd: '$',
    gel: '₾',
  }

  const currentCurrency = currency?.toLowerCase() as CurrencyCode
  return renderCurrency[currentCurrency]
}
//
//
export const numberWithSpaces = (value?: number | string) => {
  if (value) {
    var parts = value.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return parts.join('.')
  } else {
    return value
  }
}
//
// for permisions
export const Permision = (acceptedRoles?: string[] | []) => {
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth)
  //
  const currentUerType = auth?.user?.userType
  //
  const currentUerRoles = auth?.user?.companyUsers?.map((x) => x.role)
  //
  const allRols = currentUerRoles ? [...currentUerRoles, currentUerType] : [currentUerType]
  //
  const filteredRols = acceptedRoles?.filter(function (accepted_role) {
    return (
      allRols?.filter(function (current_user_roles) {
        return current_user_roles === accepted_role
      }).length !== 0
    )
  })
  //
  //
  return filteredRols
  //
}
//
//
// user roles
export enum UserRole {
  Frani_Admin = 'Frani-Admin',
  Frani_Product_Owner = 'Frani-Product-Owner',
  Insurance_Admin = 'Insurance-Admin',
  Insurance_Company_Editor = 'Insurance-Company-Editor',
  Insurance_Financial_Viewer = 'Insurance-Financial-Viewer',
  Insurance_Product_Editor = 'Insurance-Product-Editor',
  User = 'User',
}
//
//
export enum AllAdminRoles {
  Frani_Admin = 'Frani-Admin',
  Frani_Product_Owner = 'Frani-Product-Owner',
  Insurance_Admin = 'Insurance-Admin',
  Insurance_Company_Editor = 'Insurance-Company-Editor',
  Insurance_Financial_Viewer = 'Insurance-Financial-Viewer',
  Insurance_Product_Editor = 'Insurance-Product-Editor',
}
//
//
export enum FraniAdminRoles {
  Frani_Admin = 'Frani-Admin',
  Frani_Product_Owner = 'Frani-Product-Owner',
}
//
//
export enum AdminRole {
  Insurance_Admin = 'Insurance-Admin',
  Insurance_Product_Editor = 'Insurance-Product-Editor',
  Insurance_Company_Editor = 'Insurance-Company-Editor',
  Insurance_Financial_Viewer = 'Insurance-Financial-Viewer',
}
//
export type PremiumByTraveler = {
  calculatedPremiumByInsurancesDays: number
  dayPremium: string
  franchise: number
  maxAge: number
  minAge: number
  travelPackageId: number
  travelerAge: number
  travelerKey: number
  travelerBirthday: string
  guid: string
  priceId: number
}
//
export type CalculatedPrice = {
  area: string
  compensationCurrency: string
  compensationLimit: number
  startDate: string
  endDate: string
  franchise: number
  insurancesDays: number
  totalPremium: number
  travelPackageId: number
  travelType: string
  premiumByTravelers: PremiumByTraveler[]
  resultHash?: string
  guid: string
  travelerBirthday: string
}
//
export type Addon = {
  body: string
  createdAt: string
  updatedAt: string
  id: number
  image: string
  productCategoryId: number
  slug: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
//
export type Addons = {
  addon: Addon
  description: string
  franchiseDescription: string
  insuranceTranslations: TranslationsField<{
    description: string
    franchiseDescription: string
  }>
}
//
export type GroupedTravelAttachedAddon = {
  createdAt: string
  updatedAt: string
  id: number
  packageCurrency: string
  paymentType: string
  premium: number
  premiumCurrency: string
  title: string
  translations: TranslationsField<{
    title: string
  }>
  travelPackageId: number
  addons: Addons[]
}
//
export type NewTravelAttachedAddon = {
  addonId: number
  addonImage: string
  createdAt: string
  updatedAt: string
  description: string
  franchiseDescription: string
  addonTranslations: TranslationsField<{
    title: string
    body: string
  }>
  id: number
  packageCurrency: string
  paymentType: string
  premium: string
  premiumCurrency: string
  translations: TranslationsField<{
    description: string
    franchiseDescription: string
  }>
  travelPackageId: number
}
//
export type AbroadOffer = {
  brandName: TranslationsField<{
    title: string
  }>
  packageId: number
  isBest?: boolean
  calculatedPrices: CalculatedPrice
  companyId: number
  companyLogo: string
  companyName: string
  companySlug: string
  groupedTravelAttachedAddonInfos: GroupedTravelAttachedAddon[]
  travelAttachedAddons: NewTravelAttachedAddon[]
  notCoveredRisks: NotCoveredRisks[]
  productConditions: string
  productWording: string
  travelPackageId: number
  travelRisks: TravelRisk[]
}
//
export type AbroadOfferRequest = {
  categorySlug: string
  whoChoseCompany: string
  userChoseCompanies?: number[] | []
  selectedTravel: string
  betterOffer: boolean
  travelMultiDay?: number
  travelDates: {
    start: string
    end: string
  }
  travelArea: string
  selectedPackage: {
    currency: string
    limit: number
    franchise?: number
  }
  travelersAge: number[]
  selectedCoverages: []
  uniqueId: string
  travelersBirthdays: TravelersBirthdays[]
}
//
export type TravelAbrodLeftCompany = {
  productCategory: string
  travelType: string
  multiDay: number | null
  startDate: string
  endDate: string
  area: string
  currency: string
  limit: number
  ages: number[]
  step: string
}
//
export type ProgressBarType = {
  title: string
  url: string
  active: boolean
  passed: boolean
  id?: number
  isVisible?: boolean
  fullCount?: number
  currentCount?: number
}
//
export type PublicPolicy = {
  policyType: string
  companyName: string
  insurerFullName: string
  policyNumber: string
  policyStartDate: string
  policyEndDate: string
  policyStatus: string
  policyUrl: string
}
//
export type AdminTravelPolicyDetail = {
  area: string
  citizenship: string
  createdAt: string
  eachPrice: string
  gender: string
  id: number
  insuranceDays: number
  mainPolicy: UserTravelPolicy
  passportNumber: string
  personalNumber: string
  travelMultiDay: number
  travelPolicyAttachedAddons: TravelPolicyAttachedAddons[]
  travelPolicyAttachedAddonsJson: TravelPolicyAttachedAddonsJson[]
  travelType: string
  updatedAt: string
}
//
export type TravelPolicyAttachedAddons = {
  addonId: number
  addonPrice: string
  addonSlug: string
  travelAttachedAddonId: number
}
//
export type ProductCategoryEmail = {
  id: number
  productCategoryId: number
  title: string
  emails: string[]
  createdAt: string
  updatedAt: string
}
//
export type TravelPolicyAttachedAddonsJson = {
  addonId: number
  addonPrice: string
  addonSlug: string
  travelAttachedAddonId: number
}
//
export type SoldPoliciesSearchKeywords = {
  pageNumber: number
  personalNumber?: string
  productCategory?: string
  firstName?: string
  lastName?: string
  companyIds?: string
}
//
export type SoldPolicies = {
  data: CompanyPolicyDetail[]
  meta: Pagination
}
//
export type GeneratePolicyFilesProps = {
  policyType: string
  policyIds: number[]
}
//
export type GeneratePolicyFilesType = {
  key: string
  url: string
  contentType: string
  createdAt: string
  updatedAt: string
  id: number
}
//
export type Pagination = {
  current_page: number
  first_page: number
  first_page_url: string
  last_page: number
  last_page_url: string
  next_page_url: string
  per_page: number
  previous_page_url: string
  total: number
}
//
export type TplPolicyRegistration = {
  forSelf: boolean
  paymentTerm: string
  tplPackageId: number
  compensationLimit: number
  compensationCurrency: string
  price: number
  startDate: string
  endDate: string
  carInfo: {
    registerNumber: string
    techPassportNumber: string
    vinCode: string
    carType: string
    brand: string
    model: string
    yearOfManufacture: string
    engineCapacity: string
  }
  clientInfo: ClientInfo
  carOwnerInfo: CarOwnerInfo
  tplPriceId: number
}
//
export type ClientInfo = {
  franiTerms: FraniTerms
  citizenship: string
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  birthday: string
  personalNumber: string
  gender: string
  phone: string
  smsCode: number
  email: string
  password?: string
  privateNumber?: string
}
//
export type CarOwnerInfo = {
  citizenship: string
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  birthday: string
  personalNumber: string
  gender: string
  phone: string
  smsCode: number
  email: string
  idCardFiles: string[]
  driversLicenseFiles: string[]
  password?: string
}
//
export type TplPersonalInfo = {
  cityzenship: string
  personalNumber: string
  passportNumber?: string
  dateOfBirth: Date | null
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  gender: string
  idCardFrontSide: string
  idCardBackSide: string
  driverLicenseFrontSide: string
  driverLicenseBackSide: string
}
//
export type FaqTypes = {
  id: number
  parentId: number
  sort: number
  slug: string
  cover: string
  image: string
  url: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
  createdAt: string
  updatedAt: string
  title: string
  body: string
  children: FaqTypes[]
}
//
export type AboutUsType = {
  id: number
  parentId: number
  sort: number
  slug: string
  cover: string
  image: string
  url: string
  translations: TranslationsField<{
    title: string
    excerpt: string
    body: string
  }>
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
  body: string
  children: OurTeam[]
}
//
export type OurTeam = {
  id: number
  parentId: number
  sort: number
  slug: string
  image: string
  url: string
  translations: TranslationsField<{
    title: string
    excerpt: string
  }>
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
}
//
export type Footer = {
  title: TranslationsField<{
    title: string
  }>
  children: FooterSubMenu[]
}
//
export type FooterSubMenu = {
  id: number
  menuId: number
  parentId: number
  sort: number
  url: string
  createdAt: string
  updatedAt: string
  title: TranslationsField<{
    title: string
  }>
}
//
export type MainPageText = {
  id: number
  parentId: number
  sort: number
  slug: string
  cover: string
  image: string
  url: string
  translations: TranslationsField<{
    title: string
    excerpt: string
    body: string
  }>
  title: string
  excerpt: string
  body: string
  createdAt: string
  updatedAt: string
}
//
export type TplPolicyRegisrationFirstStep = {
  id: number
  listId: number
  sort: number
  type: string
  image: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
  createdAt: string
  updatedAt: string
  title: string
  body: string
}
//
export type Transation = {
  insuranceType: string
  companyName: string
  insuredName: string
  policyNumber: string
  paidAt: string
  amount: string
  transactionId: string
  status: string
  policyId?: number
  page: number
  limit: number
  companyId?: number
}

export type UserTransactions = {
  meta: {
    total: number
  }
  orders: Transation[]
}

//
export type TravelDateText = {
  type: string
  image: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
  title: string
  body: string
}
//
export type WebPage = {
  id: number
  parentId: number
  sort: number
  slug: string
  cover: string
  image: string
  url: string
  translations: TranslationsField<{
    body: string
  }>
  createdAt: string
  updatedAt: string
  children: any[] //TODO
  body: string
}
//
export type TravelPagesWording = {
  id: number
  listId: number
  sort: number
  type: string
  image: string
  translations: TranslationsField<{
    title: string
    body: string
    excerpt: string
    secondTitle: string
    thirdTitle: string
    secondExcerpt: string
    fourthTitle: string
    thirdExcerpt: string
  }>
  createdAt: string
  updatedAt: string
  title: string
  body: string
  excerpt: string
  secondTitle: string
  thirdTitle: string
  secondExcerpt: string
  fourthTitle: string
  thirdExcerpt: string
}
//
export type AdminCompany = {
  id: number
  sort: number
  slug: string
  brandName: TranslationsField<{
    title: string
  }>
  cover: string
  poster: string
  commission: number
  identificationNumber: string
  phoneNumber: string
  emailAddress: string
  url: string
  status: string
  createdAt: string
  updatedAt: string
  translations: TranslationsField<{
    title: string
    excerpt: string
    body: string
    logo: string
    brandName?: string
  }>
  title: string
  excerpt: string
  body: string
  logo: string
}
//
export type WelcomerSearchOffer = {
  days: number
  currencyId: number
  limitId: number
  packageIds: number[]
  ages: number[]
}
//
export type WelcomerOffers = {
  isBestOption: boolean
  prices: WelcomerBestOffer[]
}
//
export type WelcomerSearchDiscount = {
  days: number
  currencyId: number
  limitId: number
  packageId: number
  ages: number[]
  promoCode: string
  startDate: string
  endDate: string
  userId: number | null
  selectedCoverages: SelectedCoverages[]
}
//
export type WelcomerAgeGroup = {
  id: number
  minPremium: string
  productId: number
  ageFrom: number
  ageTo: number
}
//
export type WelcomerDayGroup = {
  id: number
  productId: number
  coeffecient: string
  minDay: number
  maxDay: number
}
//
export type WelcomerBestOffer = {
  id: number
  currencyId: number
  limitId: number
  packageId: number
  premium: number
  welcomerAgeGroup: WelcomerAgeGroup
  welcomerAgeGroupId: number
  welcomerDayGroup: WelcomerDayGroup
  welcomerDayGroupId: number
  welcomerPackage: WelcomerPackage
}
//
export type WelcomerListItem = {
  id: number
  image: string
  title: string
  body: string
  type: string
  sort: number
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
//
export type WelcomerPagesInfo = {
  id: string
  listItems: WelcomerListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//
export type AdminWebDocument = {
  id: number
  parentId: number
  sort: number
  slug: string
  cover: string
  image: string
  url: string
  version: number
  hide: boolean
  translations: TranslationsField<{
    body: string
  }>
  body: string
}
//
export type AdminMenu = {
  id: number
  url: string | null
  hide: boolean
  permissions: string[] | []
  translations: TranslationsField<{
    title: string
  }>
  sub_menu: AdminSubMenu[] | []
}
//
export type AdminSubMenu = {
  sub_menu_id: number
  url: string | null
  hide: boolean
  permissions: string[] | []
  translations: TranslationsField<{
    title: string
  }>
}
//
export type AdminCompanyUser = {
  firstName: string
  lastName: string
  email: string
  createdAt: string
  updatedAt: string
  id: number
  password: string
  companyUsers: UserType[]
}
//
export type Role = {
  id: number
  userId: number
  role: string
  createdAt: string
  updatedAt: string
}
//
export type AddUser = {
  companyId: number
  companyRoles: string[]
  firstName: string
  lastName: string
  email: string
}
//
export type AdminAuthorizedCompany = {
  id: number
  sort: number
  slug: string
  poster: string
  cover: string
  commission: number
  identificationNumber: string
  phoneNumber: string
  emailAddress: string
  url: string
  status: string
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
  body: string
  logo: string
}
//
export type TravelAddon = {
  id: number
  productCategoryId: number
  slug: string
  image: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
  title: string
  body: string
  createdAt: string
  updatedAt: string
}
//
//
export type AddTravelRisk = {
  icon: string
  translations: TranslationsField<{
    riskName: string
  }>
}
//
export type TravelRisk = {
  id: number
  icon: string
  createdAt: string
  updatedAt: string
  translations: TranslationsField<{
    riskName: string
    riskDescription?: string
  }>
  riskName: string
}
/** Many-to-many Package-TravelRisk pivot table */
export type TravelEnabledRisk = {
  id: number
  packageId: number
  travelRiskId: number
  translations: TranslationsField<{
    riskDescription: string
  }>
  risk: TravelRisk
}
//
export type TplOfferRequest = {
  whoChoseCompany: string
  userChoseCompanies?: number[]
  paymentTerm: string
  policyPeriod: {
    start: string
    end: string
  }
  carCharacteristics: {
    type: string
    releaseYear: number
  }
  selectedPackage: {
    currency: string
    limit: number
  }
}
//
export type TplOfferType = {
  companyId: number
  companyName: string
  compensationLimit: number
  companyLogo: string
  tplPackageId: number
  productWording: string
  maxAuthorizedDrivers: number
  insuranceConditionsCovered: InsuranceConditionsCovered[]
  insuranceConditionsNotCovered: InsuranceConditionsNotCovered[]
  tplPackagePrice: TplPackagePriceType
}
//
export type TplPackagePriceType = {
  id: number
  tplPackageId: number
  currency: string
  premium: number
  createdAt: string
  updatedAt: string
}
export type InsuranceConditionsNotCovered = {
  type: string
  title: string
}
//
export type InsuranceConditionsCovered = {
  type: string
  title: string
}
//
export type CarPropertiesType = {
  registerNumber: string
  techPassportNumber: string
  vinCode: string
  carType: string
  brend: string
  model: string
  yearOfManufacture: string
  engineCapacity: string
}
//
export type TplCurrencyList = {
  id: number
  listId: number
  sort: number
  type: string
  image: string
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
  body: string
  secondTitle: string
  fourthTitle: string
  thirdExcerpt: string
}
//
export type TplInsurancePeriod = {
  id: number
  slug: string
  translations: TranslationsField<{
    title: string
    body: string
    secondTitle: string
    secondBody: string
  }>
  createdAt: string
  updatedAt: string
  title: string
  body: string
  secondTitle: string
  secondBody: string
}
//
export type TplStartDateText = {
  id: number
  listId: number
  sort: null | number
  type: string
  image: string | null
  createdAt: string
  updatedAt: string
  title: string
  body: string
}
//
export type TplAndLocalTplList = {
  type: string
  image: string
  title: string
  body: string
}
//
export type PolicyRegistrationFirstStepList = {
  id: number
  listId: number
  sort: number
  type: string
  image: string
  createdAt: string
  updatedAt: string
  title: string
  body: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
//
export type TravelPolicyRegistrationResponse = {
  userId: number
  totalPrice: number
  createdAt: string
  updatedAt: string
  id: number
}
//
export type CompanyPaymentList = {
  policyNumber: string
  fullName: string
  personalNumber: string
  amount: number
  franiCommission: number
  franiPercent: number
  paymentDate: string
}
//
export type CompanyPolicyDetail = {
  id: number
  userId: number
  packageId: number
  policyNumber: string
  policyPdfUrl: string
  targetId: number
  targetType: string
  cardNumber: string | number
  birthday: string
  compensationLimit: number
  compensationCurrency: string
  franchise: number
  status: string
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
  firstName: string
  lastName: string
  companyId: number
  package: UserPackage
}
//
export type TravelPolicyRegistration = {
  travelType: string
  area: string
  promoCode: string | null | undefined
  compensationLimit: number
  uniqueId: string
  compensationCurrency: string
  insuranceType: string
  startDate: string
  endDate: string
  travelPackageId: number
  insurerInfo: InsurerInfo
  travelersInfo: TravelersInfo[]
  travelMultiDay?: number | null
  franchise: number
  gpihApiData?: GpihApiDataType
  aldagiApiData?: AldagiApiDataType
  imedilBoolean?: boolean
  sgsVerification: boolean
  betterOffer: boolean
}
//
export type CompanyPolicies = {
  id: number
  citizenship: string
  passportNumber: string
  personalNumber: string
  travelType: string
  area: string
  insuranceDays: number
  travelMultiDay: number
  eachPrice: number
  gender: string
  createdAt: string
  updatedAt: string
  mainPolicy: CompanyMainPolicy
}
//
export type CompanyMainPolicy = {
  id: number
  userId: number
  packageId: number
  policyNumber: string
  policyPdfUrl: string
  targetId: number
  targetType: string
  cardNumber: string
  birthday: string
  compensationLimit: number
  compensationCurrency: string
  franchise: number
  status: string
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
  orderItems: CompanyOrderItems[]
  firstName: string
  lastName: string
}
//
export type CompanyOrderItems = {
  id: number
  orderId: number
  policyId: number
  eachPrice: number
  createdAt: string
  updatedAt: string
  order: {
    id: number
    userId: number
    totalPrice: number
    paidAt: string | number
    discount: PromoCodeDiscount
    createdAt: string
    updatedAt: string
  }
}
//
export type PromoCodeDiscount = {
  promoCode: {
    code: string
    id: number
    name: string
    applyingDiscount: ApplyingDiscount
  }
}
//
export type ApplyingDiscount = {
  days: number
  amount: number
  percentageDiscount: {
    percentage: number
    maximumAmount: number
  }
  actualAmount: number
  discountedAmount: number
  saving: number
  percentage: number
  policyId: number
}
//
export type Policies = {
  id: number
  userId: number
  travelType: string
  area: string
  compensationLimit: number
  compensationCurrency: string
  insuranceType: string
  startDate: string
  endDate: string
  travelPackageId: number
  insurerInfo: InsurerInfo
  travelersInfo: TravelersInfo[]
  totalPrice: number
  createdAt: string
  updatedAt: string
}
//
export type UserPoliciesCardInfo = {
  mainPolicyId: number
  insuranceType: string
  companyName: string
  policyNumber: string
  insuredFullName: string
  startDate: string
  endDate: string
  status: string
}
//
export type UserCurrentPolicies = {
  id: number
  citizenship: string
  passportNumber: string
  personalNumber: string
  travelType: string
  area: string
  insuranceDays: number
  travelMultiDay: number
  eachPrice: number
  price?: number
  gender: string
  createdAt: string
  updatedAt: string
  mainPolicy: UserTravelPolicy
  attachedAddons: TravelAttachedAddon[]
  grouppedAddons: TravelAttachedAddon[]
}

//
export type UserTravelPolicyType = {
  data: UserTravelPolicy[]
  meta: Pagination
}
//
export type UserTravelPolicy = {
  id: number
  userId: number
  packageId: number
  policyNumber: string
  policyPdfUrl: string
  productWordingUrl: string
  targetId: number
  targetType: string
  cardNumber: string
  birthday: string
  compensationLimit: number
  compensationCurrency: string
  franchise: number
  status: string
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
  user: User
  firstName: string
  lastName: string
  orderItems: {
    paymentScheduleType: any
    slug: string
    orderId: number
    order: {
      discount: PromoCodeDiscount
    }
  }[]
  package: UserPackage
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
}
//
export type UserPackage = {
  id: number
  productId: number
  packageId: number
  targetId: number
  targetType: string
  compensationLimit: number
  translations: TranslationsField<{
    title: string
  }>
  discount: null // ? ask irakli
  approvedByAdmin: boolean
  status: string
  activatedDate: string
  deactivatedDate: string
  createdAt: string
  updatedAt: string
  product: Product
  compensationCurrencies: CompensationCurrencyValue[]
  franchises: number[]
  insuranceExceptions: InsuranceExceptions[]
  title: string
  travelRisks: TravelRisk[]
  wordingFileUrl: string
  welcomerEnabledRisks?: WelcomerEnabledRisk[]
  travelAttachedRisks?: TravelEnabledRisk[]
}
//
export type InsuranceExceptions = {
  translations: TranslationsField<{
    title: string
  }>
}
//
export type NotCoveredRisks = {
  translations: TranslationsField<{
    title: string
  }>
}
//
export type TravelersInfo = {
  translations: TranslationsField<{
    firstName?: string
    lastName?: string
  }>
  birthday: string
  passportNumber: string
  citizenship: string
  personalNumber?: string
  insuranceDays: number
  gender: string
  addons: number[] | []
  groupedAddonInfos: any // TODO ADD TYPES
}
//
export type InsurerInfo = {
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  birthday: string
  personalNumber: string | null
  passportNumber?: string | null
  gender: string
  email: string
  password?: string
  phone: string
  smsCode: number
  franiTerms: FraniTerms
}
//
export type FraniTerms = {
  consentTerm: boolean
  policyTerm: boolean
  privacyTerm: boolean
}
//
export type Product = {
  activatedDate: string
  deactivatedDate: string
  id: number
  companyId: number
  productCategoryId: number
  targetId: number
  targetType: string
  slug: string
  conditionFileUrl: string
  wordingFileUrl: string
  policyFileUrl: string
  approvedByAdmin: boolean
  status: string
  integrated: boolean
  activationDate: Date | null
  deactivationDate: Date | null
  createdAt: string
  updatedAt: string
  emails: string[]
  title: string
  body: string
  company: Company
  productCategory: ProductCategory
  packages: Package[]
  translations: TranslationsField<{
    title: string
    body: string
  }>
  policyNumberRanges: PolicyNumberRange[]
}
export type PolicyNumberRange = {
  id: number
  productId: number
  policyPrefix?: string | null
  policySuffix?: string | null
  minPolicyNumber: string
  maxPolicyNumber: string
  usedPolicyNumber: number
  product?: Product
  createdAt: Date
  updatedAt: Date
}
/** TravelProduct day range (e.g. 1-180 day) for grouping prices */
export type TravelDayGroup = {
  minDay: number
  maxDay: number
}
export type TravelDayGroupWithCoefficient = TravelDayGroup & {
  coefficient?: number
}

export type CompanyProductsWithSubModal = {
  id: number
  companyId: number
  productCategoryId: number
  targetId: null
  targetType: null
  slug: string
  conditionFileUrl: null
  wordingFileUrl: null
  policyFileUrl: null
  approvedByAdmin: string
  status: string
  integrated: boolean
  activationDate: null
  deactivationDate: null
  createdAt: string
  updatedAt: string
  packages: Package[]
  travelProducts: TravelProduct
  travelPackages: TravelPackage
}
//
export type Package = {
  id: number
  productId: number
  packageId: null
  targetId: number
  targetType: string
  compensationLimit: number
  discount: null
  approvedByAdmin: string
  status: string
  activatedDate: null
  deactivatedDate: null
  createdAt: string
  updatedAt: string
  title: string
  compensationCurrencies: CompensationCurrencyValue[]
  target?: TravelPackage | TplPackage | WelcomerPackage
  translations: TranslationsField<{ title: string }>
  franchises: number[]
  product: Product
  insuranceExceptions: InsuranceException[] | null
  travelRisks: TravelRisk[]
  /** @computed (generated) relationship data */
  travelAttachedRisks: TravelEnabledRisk[]
  conditionFileUrl: string
  wordingFileUrl: string
  tplInsurancePeriods?: TplInsurancePeriod[]
  tplPriceCombinations?: TplPriceCombination[]
  paymentScheduleTypeIds: number[] | null
  welcomerEnabledRisks?: WelcomerEnabledRisk[]
  wordingFiles: {
    translations: TranslationsField<{
      url: string
    }>
  }
  // policyFileUrl: string // the field exists for when we want separate PDF templates for each package (e.g. multi etc)
}
//
export type WelcomerPackage = {
  id: number
  enableAddonsOnSingle: boolean
  createdAt: string
  updatedAt: string
  mainPackage: Package
  attachedAddons?: NewWelcomerAttachedAddon[]
  groupedAttachedAddonInfos?: GroupedWelcomerAttachedAddon[]
}
//
//
export type WelcomerEnabledRisk = {
  id: number
  packageId: number
  riskId: number
  translations: TranslationsField<{
    riskDescription: string
  }>
  welcomerRisk: TravelRisk
}
//
export type CompensationCurrencyValue = {
  currency: string
}
//
export type InsuranceException = {
  translations: TranslationsField<{ title: string }>
}
//
export type TravelRisks = {
  id: number
  icon: null
  createdAt: string
  updatedAt: string
  riskName: string
}
//
export type TravelPackage = {
  id: number
  enableAddonsOnSingle: boolean
  enableAddonsOnMulti: boolean
  createdAt: string
  updatedAt: string
  travelAttachedAddons: TravelAttachedAddon[]
  travelAbroadPriceCombinations: TravelAbroadPriceCombination[]
  areas: Areas[]
  countryExceptionInfos: CountryExceptionInfo[]
  mainPackage: Package
  enableMulti: boolean
  enableSingle: boolean
  travelMultiDays: TravelMultiDayOption[]
}
//
export type CountryExceptionInfo = {
  countryISOCode: string
}
export type Currency = 'USD' | 'EUR' | 'GEL'
export const EnabledCurrencies: Currency[] = ['USD', 'EUR', 'GEL']

export type TravelAbroadArea = 'EUROPE' | 'SCHENGEN' | 'WORLD'
export const TravelAbroadAreaOptions: TravelAbroadArea[] = ['EUROPE', 'SCHENGEN', 'WORLD']

export type SingleMultiOption = 'SINGLE' | 'MULTI'
export const SingleMultiOptions: SingleMultiOption[] = ['SINGLE', 'MULTI']

export const TravelAbroadFranchiseOptions = [0, 50, 80, 150]

export type Areas = {
  area: string
}
//
export type TravelAbroadPriceCombination = {
  id: number
  /** attached to this Travel product */
  travelProductId: number
  /** controls price on this package */
  travelPackageId: number
  currency: string
  franchise: number
  minDay: number
  maxDay: number
  minAge: number
  maxAge: number
  area: string
  travelType: string
  premium: number
  isAutoGenerated: boolean
  createdAt: string
  updatedAt: string
}
//
export type PolicyFilter = {
  category?: string
  companyId?: number
  insured?: string
  page?: number
}
//
export type TravelProduct = {
  id: number
  mainProduct: Product
  minimumInsuranceDays: number
  maximumInsuranceDays: number
  limitBeforeRealization: number
  productMinPremium: number
  singleTravelMinPremium: number
  multiTravelMinPremium: number
  createdAt: Date
  updatedAt: Date
  minTotalPremiumInfos: MinTotalPremiumAgeGroupWithCoefficient[] // MinTotalPremiumByAgeInfos[]
  dayGroupInfos: TravelDayGroupWithCoefficient[]
  priceCombinations: TravelAbroadPriceCombination[]
  priceCoefficients: TravelProductPriceCoefficients
  emails: string[]
  integrated: boolean
}
//
// type AgeGroup = {
//   minAge: number
//   maxAge: number
// }
export type AgeGroupCoefficientByTravelArea = {
  area: TravelAbroadArea
  coefficient: number
}
export type MinTotalPremiumAgeGroupWithCoefficient = MinTotalPremiumByAgeInfos & {
  // coefficient?: number
  coefficientsByTravelArea?: AgeGroupCoefficientByTravelArea[]
}
//
export type MinTotalPremiumByAgeInfos = {
  minAge: number
  maxAge: number
  minTotalPremium: number
}
export type TravelProductPriceCoefficients = {
  basePrice: number
  singlePolicyCoefficient: number
  multiPolicyCoefficient: number
  franchiseCoefficients: TravelProductFranchiseCoefficient[]
  areaCoefficients: TravelProductAreaCoefficient[]
  currencyCoefficients: TravelProductCurrencyCoefficient[]
  packageCoefficients: TravelProductPackageCoefficient[]
}
export type TravelProductFranchiseCoefficient = {
  franchise: number
  coefficient: number
}
export type TravelProductAreaCoefficient = {
  area: TravelAbroadArea
  coefficient: number
}
export type TravelProductCurrencyCoefficient = {
  currency: Currency
  coefficient: number
}
export type TravelProductPackageCoefficient = {
  packageId: number
  coefficient: number
}
//
export type AdminProductCategoriesList = {
  id: number
  sort: number
  slug: string
  poster: string
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
  body: string
}
//
export type AldagiApiDataType = {
  holders?: AldagiApiDataTypeHolders[]
}
//
export type GpihApiDataType = {
  resultHash?: string
  holders?: GpihApiDataTypeHolders[]
}
//
export type AldagiApiDataTypeHolders = {
  travelerAge: number
  calculatedPremiumByInsurancesDays: number
}
//
export type GpihApiDataTypeHolders = {
  guid: string
  travelerBirthday: string
  calculatedPremiumByInsurancesDays: number
}
//
// ?? OFFER TYPES ??
export type TravelAboardBestOfferResponse = {
  companyId: number
  companyName: string
  companyLogo: string
  companySlug: string
  productWording: string
  productConditions: string
  travelPackageId: number
  travelRisks: TravelRisksType[]
  travelAttachedAddons: TravelAttachedAddon[]
  calculatedPrices: CalculatedPrices
  notCoveredRisks: NotCoveredRisks[]
}
//
export type CalculatedPrices = {
  travelPackageId: number
  selectedAddons: SelectedAddons[]
  totalPremium: number
  totalPremiumWithAddons: number
  onlyAddonsPremium: number
  insurancesDays: number
  travelType: string
  area: string
  franchise: number
  compensationLimit: number
  compensationCurrency: string
  startDate: string
  endDate: string
  premiumByTravelers: PremiumByTravelers[]
  resultHash?: string
}
//
export type SelectedAddons = {
  addonId: number
  image: string
  title: string
  body: string
  travelersCount: number
}
//
export type PremiumByTravelers = {
  travelPackageId: number
  travelerAge: number
  minAge: number
  maxAge: number
  perDayPremium: number
  calculatedPremiumByInsurancesDays: number // lukam tqva dzmao
  travelerKey?: number
  isLuggageInsured?: boolean
  addonPrice: number
  dayPremium: number
  guid: string
  travelerBirthday: string
}
//
export type TravelAttachedAddon = {
  id: number
  addonId: number
  travelPackageId: number
  paymentType: 'ONCE' | 'DAILY'
  premiumCurrency: Currency
  premium: number
  createdAt: string
  updatedAt: string
  addonInfos: AddonInfo[]
  body: string
  price: string
  title: string
}
//
export type AddonInfo = {
  currency: string
  /** this is a text comment field for this specific franchise */
  franchise: string
  translations: TranslationsField<{ description: string }>
  description: string
}
// travel enabled risks returned in best offer
export type TravelRisksType = {
  travelRiskId: number
  riskName: string
  icon: string
  createdAt: string
  updatedAt: string
  pivotData: {
    travelEnabledRiskId: number
    travelRiskId: number
    packageId: number
    riskDescription: string
  }
}
//
//
//
export type TravelAboardBestOfferPost = {
  categorySlug: string
  whoChoseCompany: string
  userChoseCompanies?: number[]
  selectedTravel: string
  travelMultiDay?: number
  travelDates: TravelSingleDates
  travelArea: string
  selectedPackage: SelectedPackage
  travelersAge: number[]
  selectedCoverages: SelectedCoverages[]
  uniqueId: string
  travelersBirthdays: TravelersBirthdays[]
}
//
export type TravelAboardDiscountPost = {
  categorySlug: string
  whoChoseCompany: string
  userChoseCompanies?: number[] | []
  selectedTravel: string
  betterOffer: boolean
  travelMultiDay?: number
  travelDates: {
    start: string
    end: string
  }
  travelArea: string
  selectedPackage: {
    currency: string
    limit: number
    franchise?: number
    id?: number
  }
  travelersAge: number[]
  selectedCoverages: SelectedCoverages[]
  travelersBirthdays: { age: number | undefined; birthday: string }[]
  promoCode: string
  userId: number | null
  policyStartDate: string
  policyEndDate: string
}
//
export type TravelersBirthdays = {
  age: number
  birthday: string
}
//
//
export type TravelSingleDates = {
  start: string
  end: string
}
//
export type Franchise = {
  value: number
  id: number
}
//
export type SelectedPackage = {
  currency: string
  limit: number
  franchise: number
}
//
export type SelectedCoverages = {
  id: number | null
  travelersCount: number
  groupedId?: number | null
}
// ?? OFFER TYPES ??
//
//
//
export type BuyerInformationType = {
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  dateOfbirth: Date | null
  idNumber: string
  gender: string
  email: string
  passwod: string
  phone: string
  smsCode: number
  cityzenship: string
}
//
//
//
export type PersonalInfoExtraCoveragetype = {
  id?: number
  title?: string
}
export type ForMePersonalInfo = {
  cityzenship: string
  passportNumber: string
  idNumber: string
  dateOfbirth: Date | null
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  gender: string
  extraCoverage: PersonalInfoExtraCoveragetype[]
  userTerms?: boolean
  franiTerms?: boolean
  insuranceCompanyTerms?: boolean
  phone: string
  email?: string
  smsCode?: string
  password?: string
  confirmPassword?: string
  userHaveAccount?: boolean
  consentTerm: boolean
  policyTerm: boolean
  privacyTerm: boolean
  sgsVerification?: boolean
  sgsVerificationSended?: boolean
  addons?: NewTravelAttachedAddon[]
  groupedAddonInfos?: GroupedTravelAttachedAddon[]
}
export type TravelSingleOrMultiPrice = {
  type: string
  singleInfo: {
    type: string
    minPrice: number
  }
  multiInfo: {
    type: string
    minPrice: number
  }
}
export type Country = {
  id: number
  isoCode: string
  createdAt: string
  updatedAt: string
  translations: TranslationsField<{
    name: string
  }>
  name: string
}
export type travelersAgeType = {
  age: string
}
export type selectedCurrencyLimitsAndFranchiseType = {
  currency: string
  limit: number
  franchise: any
}
export type TravelSingleDateType = {
  start: Date
  end: Date | null
}
export type SelectedCompanyData = {
  id: number
  title: string
}
export type ExtraCoverageTypes = {
  id: number
  travelersCount: number
}
export type ExtraCoverages = {
  id: number
  minPrice: number
  image: string
  title: string
  body: string
  children: []
}
export type TravelFranchise = {
  value: number
}
export type TravelLimits = {
  value: number
}
export type TravelCurrencies = {
  type: string
  image: string
  title: string
  excerpt: string
  body: string
  secondTitle: string
  thirdTitle: string
  secondExcerpt: string
  fourthTitle: string
  thirdExcerpt: string
  translations: TranslationsField<{
    title: string
    excerpt: string
    body: string
    secondTitle: string
    thirdTitle: string
    secondExcerpt: string
    fourthTitle: string
    thirdExcerpt: string
  }>
}
export type TravelRegions = {
  type: string
  image: string
  title: string
  excerpt: string
  body: string
  secondTitle: string
  translations: TranslationsField<{
    title: string
    body: string
    excerpt: string
    secondTitle: string
  }>
}
export type TravelMultiDayOption = {
  id: number
  sort: number
  days: number
  insuredDays: number
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
  body: string
  translations: TranslationsField<{
    title: string
    body: string
    excerpt: string
  }>
}
export type TravelProductsMinPrice = {
  type: string
  productStartPrice: number
  singleTravelStartPrice: number
  multiTravelStartPrice: number
}
export type InsuranceKinds = {
  image: string
  title: string
  body: string
  type: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
export type CompaniesWithProduct = {
  id: number
  slug: string
  logo: string
  title: string
  excerpt: string
  body: string
  type: string
}
export type WhoWillChooseCompany = {
  image: string
  title: string
  body: string
  type: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
export type DepartureAndArrival = {
  image: string
  title: string
  body: string
  type: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
export type ProductCategory = {
  hasActivePromoCode: boolean
  type: string
  image: string
  title: string
  excerpt: string
  body: string
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
//
export type StoreFraniUser = {
  adminRole: string
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirm: string
  id: number
}
//
export type AdminTravelMultiDay = {
  id: number
  sort: number
  days: number
  insuredDays: number
  translations: TranslationsField<{
    title: string
    excerpt: string
    body: string
  }>
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
  body: string
}
//
export type User = {
  activated: boolean
  id: number
  firstName: string
  lastName: string
  firstNameGe: string
  lastNameGe: string
  phone: string
  personalNumber: string
  age: string
  avatar?: any
  gender: string
  emailVerification: boolean
  identificated: boolean
  enableWebNotifications: boolean
  enableMobileNotifications: boolean
  enableEmailNotifications: boolean
  email: string
  citizenship: string
  passportNumber: string
  birthday: string
  lang: string
  userType: 'Frani-Admin' | 'Frani-Product-Owner' | 'User' | 'Insurance'
  createdAt: string
  updatedAt: string
  companyUsers: UserType[]
  sgsVerification: boolean
  agreeToTerms: {
    consentTerm: boolean
    policyTerm: boolean
    privacyTerm: boolean
  }
  roles: Role[]
}
//
export type UserType = {
  companyId: number
  createdAt: string
  id: number
  role: string
  updatedAt: string
  userId: number
}
//
export type ProfileEditableFields = {
  phone?: string
  email?: string
  age?: string
  firstNameEn?: string
  lastNameEn?: string
  firstNameGe?: string
  lastNameGe?: string
  citizenship?: string
  personalNumber?: string
  passportNumber?: string
  gender?: string
  birthday?: string
}
//
export type Company = {
  id: number
  sort: number
  slug: string
  poster: string
  logo: string
  cover: string
  commission: null
  url: string
  status: string
  createdAt: Date
  updatedAt: Date
  title: string
  excerpt: string
  body: string
  brandName: TranslationsField<{
    title: string
  }>
  translations: TranslationsField<{
    title: string
    logo: string
    body: string
    excerpt: string
  }>
}
//
export type Notification = {
  notification: {
    userId: number
    notificationType: string
    translations: TranslationsField<{
      title: string
      body: string
    }>
    createdAt: Date
    updatedAt: Date
    id: number
    title: string
    body: string
  }
}
//
export type SignUpTypes = {
  firstName: string
  lastName: string
  phone: string
  personalNumber: string
  email: string
  password: string
  passwordConfirm: string
  createdAt: Date
  updatedAt: Date
  id: number
  rememberMeToken: string
}
//
export type SubNotification = {
  userId: number
  device: string
  token: {
    endpoint: string
    keys: {
      p256dh: string
      auth: string
    }
  }
}
//
export type CreateNotification = {
  notificationType: string
  technical: boolean
  translations: TranslationsField<{
    title: string
    message: string
  }>
}
//
export type NotificationList = {
  id: number
  userId: number
  notificationType: string
  technical: boolean
  translations: TranslationsField<{
    title: string
    message: string
  }>
  readAt: null | Date
  createdAt: Date
  updatedAt: Date
  title: string
  message: string
}
//
export type WebMenu = {
  id: number
  menuId: number
  parentId?: any
  sort: number
  url: string
  translations: TranslationsField<{
    title: string
  }>
  createdAt: string
  updatedAt: string
  subMenu: WebMenu[]
  title: string
}
//
export type AdminCurrentUserCompany = {
  id: number
  sort: number
  slug: string
  poster: string
  logo: string
  cover: string
  commission?: any
  url: string
  status: string
  createdAt: string
  updatedAt: string
  title: string
  excerpt: string
  body: string
}
//
export type SgsRequestType = {
  birthday: string
  personalNumber: string
}
//
export type SgsPhotoType = {
  id: string
  image: string
}
//
export type SgsRegistrationType = {
  activeAddressAddressID: string
  activeAddressAddressSource: string
  addressStatus: number
  activeAddressRegion: string | null
  activeAddressRaion: string | null
  addressStatusName: string
  oA_Id: string
  activeAddress: string
}
//
export type SgsDocumentType = {
  docTypeId: string
  docTypeName: string
  id: string
  serial: string
  number: string
  issueDate: string
  issuerName: string
  issuerCountryCode: string
  issuerCountry: string
  validDate: string
  uiId: null
  status: number
  statusName: string
}
//
export type SgsMessage = {
  id: number | null
  privateNumber: string
  firstName: string
  lastName: string
  firstNameEng: string | null
  lastNameEng: string | null
  birthPlace: string
  birthCountry: string
  birthCountryCode: string
  birthRegion: string
  birthDate: string
  gender: number
  personStatus: number
  personStatusName: string
  citizenshipCountry: string
  citizenshipCountryCode: string
  photo: SgsPhotoType
  registration: SgsRegistrationType
  document: SgsDocumentType
}
//
export type SgsResponse = {
  message: SgsMessage
  status: {
    type: number
    text: string
  }
  lastCheckDate: null
  origin: string
  requestId: string
}
//
export type SgsType = {
  sgsId: number | null
  request: SgsRequestType
  response: SgsResponse
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  birthday: string
  personalNumber: string
  gender: string
  status: string
  sgsStatusCode: number
  sgsStatusText: string
  createdAt: string
  updatedAt: string
  id: number
  firstName: string
  lastName: string
}
//
export type WhatPeopleSay = {
  type: string
  image: string
  title: string
  body: string
}
export type PendingPolicyNewOrderResponse = {
  order: {
    createdAt: string
    id: number
    totalPrice: number
    updatedAt: string
    userId: number
    discounted: boolean
    currentPaymentAmount: number
    validPromoCode: boolean
  }
  orderItems: {
    policyId: number
    startDate: string
    endDate: string
    eachPrice: number
    monthlyPrice: number
    oldPrice: number
    totalPrice: number
    paymentScheduleTypeId: number
    paymentScheduleTypeSlug: string
    price?: string
    currency: string
    company: TranslationsField<{
      title: string
      excerpt: string
      body: string
      logo: string
    }>
    category: TranslationsField<{
      title: string
      excerpt: string
      body: string
    }>
    slug: string
  }[]
}
//insurance user flow

//1st step
export type InsuranceForWho = {
  image: string
  title: string
  body: string
  type: string
}

//
//tpl flow
export type GroupedTplAttachedAddon = {
  createdAt: string
  updatedAt: string
  id: number
  packageCurrency: string
  paymentType: string
  premium: number
  premiumCurrency: string
  title: string
  translations: TranslationsField<{
    title: string
  }>
  travelPackageId: number
  addons: Addons[]
}
export type NewTplAttachedAddon = {
  addonId: number
  addonImage: string
  createdAt: string
  updatedAt: string
  description: string
  franchiseDescription: string
  addonTranslations: TranslationsField<{
    title: string
    body: string
  }>
  id: number
  packageCurrency: string
  paymentType: string
  premium: string
  premiumCurrency: string
  translations: TranslationsField<{
    description: string
    franchiseDescription: string
  }>
  tplPackageId: number
}
export type TplForMePersonalInfo = {
  cityzenship: string
  passportNumber: string
  idNumber: string
  dateOfbirth: Date | null
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  gender: string
  extraCoverage: PersonalInfoExtraCoveragetype[]
  userTerms?: boolean
  franiTerms?: boolean
  insuranceCompanyTerms?: boolean
  phone: string
  email?: string
  smsCode?: string
  password?: string
  confirmPassword?: string
  userHaveAccount?: boolean
  consentTerm: boolean
  policyTerm: boolean
  privacyTerm: boolean
  sgsVerification?: boolean
  sgsVerificationSended?: boolean
  addons?: NewTplAttachedAddon[]
  groupedAddonInfos?: GroupedTplAttachedAddon[]
}
export type TplOffer = {
  brandName: string
  isBest?: boolean
  // calculatedPrices: CalculatedPrice
  companyId: number
  companyLogo: string
  companyName: string
  companySlug: string
  groupedTplAttachedAddonInfos: GroupedTplAttachedAddon[]
  tplAttachedAddons: NewTplAttachedAddon[]
  notCoveredRisks: NotCoveredRisks[]
  productConditions: string
  productWording: string
  tplPackageId: number
  // tplRisks: TplRisk[]
}
//tpl flow
export type TplListItem = {
  id: number
  image: string
  title: string
  body: string
  type: string
  sort: number
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
export type TplOrLocalTpl = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//
export type TplInsurancePeriodType = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//
export type TplCurrencies = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//
export type TplChoise = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//
export type TplBestOffer = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//
export type TplOffers = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//
export type TplInsureChoice = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
export type TplPersonalInfoType = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
export type TplInsuredPersonsInfo = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
export type TplCarInfo = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
export type TplContactInfo = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
export type TplCheckInfo = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
//tpl product
export type TplPolicyNumberRange = {
  id: number
  productId: number
  policyPrefix: string
  minPolicyNumber: string
  maxPolicyNumber: string
  usedPolicyNumber: number
  createdAt: string
  updatedAt: string
  policySuffix: string
  warningAlertRanges: any // Replace with appropriate type if available
  numerationEmailSent: boolean
}
export type TplInsuranceCondition = {
  type: string
  translations: TranslationsField<{
    title: string
  }>
}
export type TplCarCategory = {
  id: number
  slug: string
  translations: TranslationsField<{
    title: string
  }>
  createdAt: string
  updatedAt: string
  title: string
}
export type TplMainProduct = {
  id: number
  companyId: number
  productCategoryId: number
  targetId: number
  targetType: string
  slug: string
  conditionFileUrl: string
  wordingFileUrl: string
  policyFileUrl: string
  approvedByAdmin: boolean
  status: string
  integrated: boolean
  translations: TranslationsField<{
    title: string
    body: string
  }>
  activatedDate: string | null
  deactivatedDate: string | null
  createdAt: string
  updatedAt: string
  emailsJson: string[]
  commission: string
  policyNumberRanges: PolicyNumberRange[]
  emails: string[]
  title: string
  body: string
}

export type TplProduct = {
  id: number
  productMinPremium: string
  maxAuthorizedDrivers: number
  maxCarReleaseYear: number
  paymentTermsJson: string[]
  insuranceConditionsJson: TplInsuranceCondition[]
  createdAt: string
  updatedAt: string
  tplCarCategories: TplCarCategory[]
  mainProduct: TplMainProduct
  paymentTerms: string[]
  insuranceConditions: TplInsuranceCondition[]
  productMarketingPrice: number
  limitationOfStartDate: number
  limitBeforeRealization: number
}

export type TplServicesInfo = {
  id: string
  listItems: TplListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
  slug: string
  createdAt: string
  updatedAt: string
}
export type TplPriceCombination = {
  id: number
  tplProductId: number
  tplPackageId: number
  currency: string
  premium: string
  createdAt: string
  updatedAt: string
}
export type TplPackage = {
  id: number
  createdAt: string
  updatedAt: string
  mainPackage: Package
  tplInsurancePeriods: TplInsurancePeriod[]
  tplPriceCombinations: TplPriceCombination[]
  compensationLimitsType: number
  minAgeOfCarRelease: number
  minAgeOfTheDriver: number
  maxAgeOfTheDriver: number
  destination: TranslationsField<{
    name: string
  }>
  priorityText: string
  packageServiceRelations: PackageServiceRelation[]
  packageServiceIds?: number[]
  coveragesTranslationsArray: TplCoverageTranslations[]
  importantTermsTranslationsArray: TplImportantTermTranslations[]
  tplCoverages: TplCoverage[]
  tplImportantTerms: TplImportantTerm[]
}
export type PackageServiceRelation = {
  id: number
  tplPackageId: number
  packageServiceId: number
  createdAt: Date
  updatedAt: Date
  packageService: PackageService
}
export type PackageService = {
  id: number
  slug: string
  sort: number | null
  comment: string | null
  translations: TranslationsField<{
    name: string
  }>
  createdAt: Date
  updatedAt: Date
}
export type TplCarInfoType = {
  registrationNumber: string
  techPassport: string
  vinCode: string
  carType: string
  brand: string
  model: string
  yearOfManufacture: string
  engineCapacity: string
}
export type TplCoverageTranslations = {
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
export type TplImportantTermTranslations = {
  translations: TranslationsField<{
    title: string
    body: string
  }>
}
export type TplCoverage = {
  createdAt: string
  updatedAt: string
  id: number
  packageId: number
  title: string
  translations: TplCoverageTranslations
}

export type TplImportantTerm = {
  createdAt: string
  updatedAt: string
  id: number
  packageId: number
  title: string
  translations: TplImportantTermTranslations
}

export type PackageServices = {
  id: number
  comment: string
  slug: string
  sort: number
  iconPath: string
  translations: TranslationsField<{
    name: string
    comment: string
  }>
  createdAt: Date
  updatedAt: Date
}

export type TplLeftCompanies = {
  companiesCount: number
  currencies: string
  limits: number[]
  packageServices: PackageServices[]
  companies: Partial<Company>[]
}
//health

// this is fake calculated prices types for fake companies object in health insurance
export type HealthCalculatedPrice = {
  monthly: number
  annual: number
}
//
export type HealthRisk = {
  healthRiskId: string
}
//

export type HealthOffer = {
  brandName: TranslationsField<{
    title: string
  }>
  isBest?: boolean
  calculatedPrices: HealthCalculatedPrice
  companyId: number
  companyLogo: string
  companyName: string
  companySlug: string
  productConditions: string
  productWording: string
  healthRisks: HealthRisk[]
  packageServices: {
    ambulatory: {
      limit: string
      payment: string
    }
    medicines: {
      limit: string
      payment: string
    }
    hospitalization: {
      limit: string
      payment: string
    }
    dentistry: {
      limit: string
      payment: string
    }
  }
  services: {
    hospitalization: {
      covered: boolean
      waitingPeriod: number
    }
    pregnancy: {
      covered: boolean
      waitingPeriod: number
    }
    medicines: {
      covered: boolean
      waitingPeriod: number
    }
    dayHospitalization: {
      covered: boolean
      waitingPeriod: number
    }
  }
  healthPackageId: number
}

export type HealthForMePersonalInfo = {
  cityzenship: string
  passportNumber: string
  idNumber: string
  dateOfbirth: string | null
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  gender: string
  insuredRecently: boolean
  IdImageFront: string | null
  IdImageBack: string
  extraCoverage: PersonalInfoExtraCoveragetype[]
  userTerms?: boolean
  franiTerms?: boolean
  insuranceCompanyTerms?: boolean
  phone: string
  email?: string
  smsCode?: string
  password?: string
  confirmPassword?: string
  userHaveAccount?: boolean
  consentTerm: boolean
  policyTerm: boolean
  privacyTerm: boolean
  sgsVerification?: boolean
  sgsVerificationSended?: boolean
}

export type Covered = {
  travelRiskId: number
  icon: string
  translations: TranslationsField<{
    title: string
    description: string
  }>
}
export type NotCovered = {
  id: number
  translations: TranslationsField<{
    description: string
  }>
}

export type Risks = {
  translations: TranslationsField<{
    coveredTitle: string
    notCoveredTitle: string
  }>
  covered: Covered[]
  notCovered: NotCovered[]
}

export type ProductDetailType = {
  id: number
  type: string
  photoUrl: string
  createdAt: Date
  updatedAt: Date
  translations: TranslationsField<{
    title: string
    description: string
    importantConditions: string
  }>
  risks: Risks
  fileKey: string
  companyProfile: TranslationsField<{
    title: string
    description: string
  }>
}

export type CriticalIllnessForMePersonalInfo = {
  id: number
  cityzenship: string
  passportNumber: string
  idNumber: string
  dateOfbirth: string | null
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  gender: string
  extraCoverage: PersonalInfoExtraCoveragetype[]
  userTerms?: boolean
  franiTerms?: boolean
  insuredRecently: boolean
  IdImageFront: string | null
  IdImageBack: string
  insuranceCompanyTerms?: boolean
  phone: string
  email?: string
  smsCode?: string
  password?: string
  confirmPassword?: string
  userHaveAccount?: boolean
  consentTerm: boolean
  policyTerm: boolean
  privacyTerm: boolean
  sgsVerification?: boolean
  sgsVerificationSended?: boolean
}

// fake critical illness type for company services
export type CompanyServices = {
  title: string
  covered: boolean
}

export type CriticalIllnessOffer = {
  brandName: TranslationsField<{
    title: string
  }>
  isBest?: boolean
  calculatedPrices: HealthCalculatedPrice
  companyId: number
  companyLogo: string
  companyName: string
  companySlug: string
  productConditions: string
  productWording: string
  services: CompanyServices[]
  healthRisks: HealthRisk[]
}
export type TplPaymentMethod = {
  id: number
  number: number
  slug: string
  translations: TranslationsField<{
    title: string
    description: string
  }>
  isActive: boolean
  createdAt: Date
}
export type BusinessHealthInfo = {
  companyName: string
  idNumber: string
  activityField: string
  comment: string
  firstName: string
  lastName: string
  position: string
  email: string
  mobileNumber: string
  type: string
  otherInformation: Partial<{
    employeesCount: number
    insuredEmployeesCount: number
    topManagementCount: number
    averageAge: number
    genderMale: string
    genderFemale: string
    minInsurancePackage: number
    insurancePayer: string
    usedInsuranceLastYear: boolean
    insuranceCompanyName: string
    contractRenewalDate: string
  }>
}

export type BusinessCarInfo = {
  companyName: string
  idNumber: string
  activityField: string
  comment: string
  firstName: string
  lastName: string
  position: string
  email: string
  mobileNumber: string
  type: string
  otherInformation: Partial<{
    carBrand: string
    carModel: string
    manufactureYear: number
    purpose: string
    currency: string
    marketValue: number
  }>
}

export type BusinessPropertyInfo = {
  companyName: string
  idNumber: string
  activityField: string
  comment: string
  firstName: string
  lastName: string
  position: string
  email: string
  mobileNumber: string
  type: string
  otherInformation: Partial<{
    cadastralCode: string
    propertyType: string
    insurePropertyContents: boolean
    address: string
    purpose: string
    currency: string
    marketValue: number
  }>
}

export type BusinessOtherInfo = {
  companyName: string
  idNumber: string
  activityField: string
  comment: string
  firstName: string
  lastName: string
  position: string
  email: string
  mobileNumber: string
  type: string
  otherInformation: Partial<{
    insuranceProduct: string
  }>
}

export type TplCoverages = {
  id: number
  tplPackageId: number
  translations: TranslationsField<{
    title: string
    body: string
  }>
  createdAt: Date
  deletedAt: Date
  updatedAt: Date
}

export type TplResponsePackage = {
  createdAt: Date
  deletedAt: Date
  updatedAt: Date
  destination: string
  id: number
  productId: number
  importantTerms: Partial<TplCoverages>[]
  maxAgeOfTheDriver: number
  minAgeOfCarRelease: number
  minAgeOfTheDriver: number
  priorityText: string
  tplPackage: {
    id: number
    coverages: TplCoverages[]
    importantTerms: Partial<TplCoverages>[]
  }
}

export type DiscountList = {
  actual: number
  discounted: number
  saving: number
}

export type DiscountedPaymentList = {
  firstPaymentAmount: DiscountList
  monthlyPaymentAmount: DiscountList
  totalPaymentAmount: DiscountList
}

export type PaymentList = {
  monthlyPaymentAmount: number
  firstPaymentAmount: number
  totalPaymentAmount: number
}

export type TplOfferResponse = {
  companyId: number
  currency: string
  cover: string
  days: number
  payment: PaymentList
  monthlyPaymentAmount: number
  firstPaymentAmount: number
  isBestOption: boolean
  limit: number
  paymentScheduleType: TplPaymentMethod
  policyType: string
  packageServices: PackageServices[]
  termsPath: {
    translations: TranslationsField<{
      url: string
    }>
  }
  totalPaymentAmount: number
  package: TplResponsePackage
  translations: TranslationsField<{
    title: string
    body: string
    logo: string
  }>
  brandName: TranslationsField<{
    title: string
  }>
  tplPriceId: number
}

export type TplCompareRequest = {
  productCategorySlug: string
  startDate: string
  endDate: string
  paymentScheduleTypeIds: number[]
  companies: {
    id: number
    currency: number
    limit: number
  }[]
}

export type TplCompareCompany = {
  cover: string
  currency: string
  firstPaymentAmount: number
  id: number
  isBestOption: boolean
  limit: number
  monthlyPaymentAmount: number
  totalPaymentAmount: number
  packageServices: PackageServices[]
  paymentScheduleType: TplPaymentMethod
  wordingFilePath: {
    translations: TranslationsField<{
      url: string
    }>
  }
  package: [] //TODO
}

export type TplCompareResponse = {
  insuranceDays: number
  companies: TplCompareCompany[]
}

export type TplCarOption = {
  bodyNumber: string
  chassisNumber: string
  color: string
  engineNumber: string
  firstName: string
  lastName: string
  fullInfo: string
  fuelType: string
  orgCode: string
  orgName: string
  ownerType: string
  weight: string
  resultCode: number
  certNumber: string
}

export type TplCarInfoResponse = {
  id: number
  registrationNumber: string
  privateNumber: string
  externalId: any
  type: string
  brand: string
  model: string
  engineCapacity: string
  vinCode: string
  releasedAt: number
  issuedAt: string
  option: TplCarOption
  createdAt: string
}

export type RegisterTplPolicyRequest = {
  tplPriceId: number
  forSelf: boolean
  paymentScheduleTypeId: number
  packageServiceIds: number[]
  promoCode: string | null
  productId: number
  tplPackageId: number
  compensationLimit: number
  compensationCurrency: string
  price: number
  smsCode: string
  startDate: string
  endDate: string
  carInformation: {
    registrationNumber: string
    techPassportNumber: string
    vinCode: string
    carType: string
    brand: string
    model: string
    manufactureYear: number
    engineCapacity: string
  }
  clientInformation: {
    terms: FraniTerms
    citizenship: string
    birthday: string
    privateNumber: string
    gender: string
    phoneNumber: string
    email: string
    password: string
    sgsVerification: boolean
    translations: TranslationsField<{
      firstName: string
      lastName: string
    }>
  }
  carOwnerInformation: {
    citizenship: string
    translations: TranslationsField<{
      firstName: string
      lastName: string
    }>
    birthday: string
    personalNumber: string
    gender: string
  }
}
//
export type GroupedWelcomerAttachedAddon = {
  createdAt: string
  updatedAt: string
  id: number
  packageCurrency: string
  paymentType: string
  premium: number
  premiumCurrency: string
  title: string
  translations: TranslationsField<{
    title: string
  }>
  welcomerPackageId: number
  addons: Addons[]
}
//
export type NewWelcomerAttachedAddon = {
  addon: Addon
  addonId: number
  createdAt: string
  updatedAt: string
  description: string
  franchiseDescription: string
  id: number
  packageCurrency: string
  paymentType: string
  premium: string
  premiumCurrency: string
  translations: TranslationsField<{
    description: string
    franchiseDescription: string
  }>
  welcomerPackageId: number
}
//
export type WelcomerRisks = {
  id: number
  icon: string
  createdAt: string
  updatedAt: string
  riskName: string
  pivotData: {}
}

//
export type WelcomerForMePersonalInfo = {
  cityzenship: string
  passportNumber: string | null
  idNumber: string | null
  dateOfbirth: Date | null
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  gender: string
  extraCoverage: PersonalInfoExtraCoveragetype[]
  userTerms?: boolean
  franiTerms?: boolean
  insuranceCompanyTerms?: boolean
  phone: string
  email?: string
  smsCode?: string
  password?: string
  confirmPassword?: string
  userHaveAccount?: boolean
  consentTerm: boolean
  policyTerm: boolean
  privacyTerm: boolean
  sgsVerification?: boolean
  sgsVerificationSended?: boolean
  addons?: NewWelcomerAttachedAddon[]
  groupedAddonInfos?: GroupedWelcomerAttachedAddon[]
}
//
export type WelcomerLeftCompany = {
  productCategory: string
  startDate: string
  endDate: string
  currency: string
  limit: number
  ages: number[]
  step: string
}
//
export type WelcomerCurrencyList = {
  id: number
  currencyCode: string
  symbolOrName: string
  isActive: boolean
  createdAt: Date
  updatedAt: Date
}
//
export type WelcomerLimitsList = {
  id: number
  value: number
  isActive: boolean
  createdAt: Date
  updatedAt: Date
}
//
export type TouristPackageInfo = {
  id: number
  attachedAddonIds: number[]
  groupedAttachedAddonInfoIds: number[]
}
//
export type TouristPrices = {
  id: number
  limitId: number | undefined
  currencyId: number | undefined
  welcomerAgeGroupId: number
  welcomerDayGroupId: number
  premium: number
  welcomerPackage: TouristPackageInfo
}
//
export type TouristsInfo = {
  translations: TranslationsField<{
    firstName?: string
    lastName?: string
  }>
  birthday: string
  passportNumber: string | null
  personalNumber: string | null
  insuranceDays: number
  citizenship: string
  gender: string
  price: TouristPrices
}
//
export type WelcomerPolicyRegistration = {
  welcomerPackageId: number
  sgsVerification: boolean
  betterOffer: boolean
  startDate: string
  endDate: string
  compensationLimit: number
  compensationCurrency: string
  franchise: number
  promoCode: string | null | undefined
  insurerInfo: InsurerInfo
  touristsInfo: TouristsInfo[]
}
//
export type WelcomerPolicyRegistrationResponse = {
  userId: number
  totalPrice: number
  createdAt: string
  updatedAt: string
  id: number
}
//
export type WelcomersDatePrices = {
  priceId: number
  date: string | null
  age: number
  welcomerAgeGroupId: number
  welcomerDayGroupId: number
  premium: number
}
//
export type TplPolicyDetailTypes = {
  id: number
  citizenship: string
  gender: string
  price: number
  paymentTerm: null
  forSelf: boolean
  idCardFiles: null
  driversLicenseFiles: null
  carRegistrationCertificateDate: string
  registerNumber: string
  techPassportNumber: string
  vinCode: string
  carType: string
  brand: string
  model: string
  yearOfManufacture: number
  engineCapacity: string
  createdAt: Date
  updatedAt: Date
  mainPolicy: TplMainPolicyTypes
  personalNumber: string
}

export type TplMainPolicyTypes = {
  orderItems: {
    paymentScheduleType: any
    slug: string
    orderId: number
    order: {
      discount: PromoCodeDiscount
    }
  }[]
  status: string
  policyPdfUrl: string
  productWordingUrl: string
  targetType: string
  package: TplPackageTypes
  policyNumber: string
  startDate: string
  endDate: string
  updatedAt: string
  compensationLimit: number
  compensationCurrency: string
  firstName: string
  lastName: string
  birthday: string
  personalNumber: string
}

export type PetPolicyDetailTypes = {
  id: number
  citizenship: string
  gender: string
  price: number
  paymentTerm: null
  createdAt: Date
  updatedAt: Date
  breed: PetBreed
  animalCategory: PetCategories
  mainPolicy: PetMainPolicyTypes
  petInformation: PetInformation
  personalNumber: string
  refundAmount: string
}

export type PetInformation = {
  name: string
  age: {
    year: number
    month: number
  }
  color: string
  gender: string
}

export type PetPackageTypes = {
  product: any
  target: any
  wordingFiles: {
    translations: TranslationsField<{
      url: string
    }>
  }
}

export type PetMainPolicyTypes = {
  orderItems: {
    paymentScheduleType: any
    slug: string
    orderId: number
    order: {
      discount: PromoCodeDiscount
    }
  }[]
  status: string
  policyPdfUrl: string
  productWordingUrl: string
  targetType: string
  package: PetPackageTypes
  policyNumber: string
  startDate: string
  endDate: string
  updatedAt: string
  compensationLimit: number
  compensationCurrency: string
  firstName: string
  lastName: string
  birthday: string
  personalNumber: string
}

export type TplMonthlyPaymentSchedule = {
  id: number
  orderId: number
  transactionId: string
  amount: number
  status: 'init' | 'paid' | 'failed'
  createdAt: Date
  updatedAt: Date
  detailJson: {
    paymentDate: Date
    transferAmount: number
  }
  details: {
    paymentDate: Date
    transferAmount: number
  }
}

export type TplPaymentSchedule = {
  payments: TplMonthlyPaymentSchedule[]
  nextPaymentDate: Date
  nextPaymentAmount: number
  totalRemainedPaymentAmount: number
  currency: string
}

export type ScheduleType = {
  nextPaymentDate: Date
  nextPaymentAmount: string | number
  totalPaymentAmount: string | number
  currency: string
  schedule: {
    name: string
    active: boolean
    passed: boolean
    amount: string | number
  }[]
}

export type TplPackageTypes = {
  target: any
  product: Product
}

export type TplTransactions = {
  carRegistrationNumber: string
  companyTranslations: TranslationsField<{
    title: string
  }>
  policyNumber: string
  transferDate: Date
  transferAmount: number
  status: string
}

export type PetInsurer = {
  cityzenship: string
  idNumber: string
  dateOfbirth: Date | null
  translations: TranslationsField<{
    firstName: string
    lastName: string
  }>
  gender: string
  userTerms?: boolean
  franiTerms?: boolean
  insuranceCompanyTerms?: boolean
  phone: string
  email?: string
  smsCode?: string
  password?: string
  confirmPassword?: string
  userHaveAccount?: boolean
  consentTerm: boolean
  policyTerm: boolean
  privacyTerm: boolean
  sgsVerification?: boolean
  sgsVerificationSended?: boolean
}

export type PetPageInfo = {
  createdAt: Date
  updatedAt: Date
  id: number
  slug: string
  listItems: WelcomerListItem[]
  translations: TranslationsField<{
    title: string
    stepName: string
  }>
}

export type PetCategories = {
  alias: string
  createdAt: Date
  updatedAt: Date
  id: number
  sort: number
  translations: TranslationsField<{
    name: string
  }>
}

export type PetBreed = {
  id?: number | null
  categoryId: number
  translations: TranslationsField<{
    name: string
  }>
  status: string
}

export type PetLeftCompany = {
  startDate: string
  endDate: string
  step: string
  animalCategoryId: number
  breedId: number | null
  otherBreedName: string
  petAge: {
    month: number
    year: number
  }
}

export type PetOfferPayload = {
  startDate: string
  endDate: string
  animalCategoryId: number
  breedId: number | null
  petAge: {
    month: number
    year: number
  }
  otherBreedName: string
  paymentScheduleTypeIds: number[]
}

export type PetPackage = {
  id: number
  animalCategoryIds: number[]
  exceptionBreedIds: number[]
  minAge: number
  maxAge: number
  createdAt: Date
  updatedAt: Date
  deletedAt: Date
}

export type PetPackageServices = {
  packageService: {
    createdAt: Date
    updatedAt: Date
    id: number
    priority: number
    status: string
    translations: TranslationsField<{
      name: string
    }>
  }
  common: {
    cover: string
    id: number
    translations: TranslationsField<{
      limit: string
      waitPeriod: string
    }>
  }
}

export type PetImportanTerms = {
  createdAt: Date
  updatedAt: Date
  id: number
  petPackageId: number
  translations: TranslationsField<{
    title: string
    body: string
  }>
}

export type PetCoverages = {
  id: number
  createdAt: Date
  updatedAt: Date
  petPackageId: number
  translations: TranslationsField<{
    title: string
    body: string
  }>
}

export type PetServices = {
  id: number
  createdAt: Date
  updatedAt: Date
  priority: number
  status: string
  translations: TranslationsField<{
    name: string
  }>
}

export type PetServicesWithCommon = {
  common: {
    id: number
    translations: TranslationsField<{
      comment: string
    }>
  }
  petService: {
    id: number
    createdAt: Date
    updatedAt: Date
    priority: number
    status: string
    translations: TranslationsField<{
      name: string
    }>
  }
}

export type PetPackageResponse = {
  id: number
  productId: number
  targetId: number
  targetType: string
  compensationLimit: number
  compensationCurrencies: CompensationCurrencyValue[]
  translations: TranslationsField<{
    title: string
  }>
  insuranceExceptionsJson: string
  franchisesJson: string
  discount: number
  approvedByAdmin: boolean
  status: string
  activatedDate: Date
  deactivatedDate: Date
  deletedAt: Date
  createdAt: Date
  updatedAt: Date
  conditionFileUrl: string
  wordingFileUrl: string
  policyFileUrl: string
  paymentScheduleTypeIds: number[]
  petPackage: PetPackage
}

export type PetBestOffer = {
  companyId: number
  cover: string
  brandName: TranslationsField<{
    title: string
  }>
  translations: TranslationsField<{
    title: string
    body: string
    logo: string
  }>
  currency: string
  limit: number
  policyType: string
  days: number
  paymentScheduleType: TplPaymentMethod
  petPriceId: number
  monthlyPaymentAmount: number
  firstPaymentAmount: number
  totalPaymentAmount: number
  package: PetPackageResponse
  termsPath: {
    translations: TranslationsField<{
      url: string
    }>
  }
  isBestOption: boolean
  importantTerms: PetImportanTerms[]
  coverages: PetCoverages[]
  packageServicesWithCommon: PetPackageServices[]
  petServicesWithCommon: PetServicesWithCommon[]
}

export type RegisterPetPolicy = {
  paymentTerm: string
  animalCategoryId: number
  breedId: number | null
  otherBreedName: string
  petPackageId: number
  compensationLimit: number
  compensationCurrency: string
  petPriceId: number
  price: number
  smsCode: string
  startDate: string
  endDate: string
  productId: number
  paymentScheduleTypeId: number
  promoCode: string | null
  culture: {
    lang: string
  }
  clientInformation: {
    terms: FraniTerms
    citizenship: string
    birthday: string
    privateNumber: string
    gender: string
    phoneNumber: string
    email: string
    password: string
    sgsVerification: boolean
    translations: TranslationsField<{
      firstName: string
      lastName: string
    }>
  }
  petInformation: {
    name: string
    age: {
      year: number
      month: number
    }
    color: string
    gender: string
  }
}

export type InformationSheet = {
  id: number
  productCategoryId: number
  attachmentSlug: string
  attachment: TranslationsField<{
    url: string
  }>
  createdAt: Date
  updatedAt: Date
}

export type FraniPartnerType = {
  id: number
  createdAt: Date
  updatedAt: Date
  imageUrl: string
  webAddress: string
  translations: TranslationsField<{
    title: string
    body: string
    secondBody: string
    thirdBody: string
  }>
}

export type PromoCode = {
  key: number
  id: number
  name: string
  translations: TranslationsField<{
    name: string
  }>
  code: string
  capacity: number | null | undefined
  remainedCapacity: number
  usageAmount: number | undefined
  applyingDiscount: ApplyingDiscount
  status: string
  startDate: Date | string
  endDate: Date | string
  activationDate: Date | string | null
  imageUrl: string
  productIds: number[]
  userIds: number[]
  type: 'amount' | 'percentage' | 'days'
  // productPromoCodes: ProductPromoCodes[]
  // options: PromoCodeOption
}

export type LandingSlide = {
  photoUrl: string
  promoCode: string
  productCategoryId: number
  redirectUrl: string
  translations: TranslationsField<{
    title: string
    description: string
    btnText: string
  }>
}

export const avatarUrl = (user: User) => (user?.avatar ? uploadUrl(user.avatar) : undefined)
